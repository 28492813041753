<template>
    <el-scrollbar class="eCommerce-container">
        <el-breadcrumb separator=">" class="genera-breadcrumb" style="line-height: 42px; margin-left: 20px;">
            <el-breadcrumb-item>客户信息</el-breadcrumb-item>
            <el-breadcrumb-item>客户详情</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="top-bar">
            <span class="text">客户详情</span>
        </div>
        <div class="detail-box">
            <div class="detail-item" style="width: 33%">
                <div class="detail-title">客户信息</div>
                <div class="customer-info">
                    <div class="info-item">
                        <div class="item-left">客户名称</div>
                        <div class="item-right">{{infoDetail.nick_name}}</div>
                    </div>
                    <!-- <div class="info-item">
                        <div class="item-left">客户姓名</div>
                        <div class="item-right">{{infoDetail.name}}</div>
                    </div> -->
                    <div class="info-item">
                        <div class="item-left">性别</div>
                        <div class="item-right">
                            <div class="sex-item">
                                <i class="iconfont" :class="infoDetail.sex === '女' ? 'rose-red' : ''">&#xe607;</i>女
                            </div>
                            <div class="sex-item">
                                <i class="iconfont" :class="infoDetail.sex === '男' ? 'light-blue' : ''">&#xe608;</i>男
                            </div>
                        </div>
                    </div>
                    <div class="info-item">
                        <div class="item-left">联系电话</div>
                        <div class="item-right">{{infoDetail.phone}}</div>
                    </div>
                    <div class="info-item">
                        <div class="item-left">年龄</div>
                        <div class="item-right">{{infoDetail.age}}</div>
                    </div>
                    <div class="info-item">
                        <div class="item-left">收货地址</div>
                        <div class="item-right">{{infoDetail.address}}</div>
                    </div>
                </div>
            </div>
            <div class="detail-item" style="width: 40%">
                <div class="detail-title">购买信息</div>
                <div class="buy-info">
                    <div class="info-line">
                        <div class="info-item">
                            <div class="item-top">
                                <i class="iconfont gradient-blue">&#xeb5d;</i>首购时间
                            </div>
                            <div class="item-content">{{infoDetail.first_transactions_time | formatDate}}</div>
                        </div>
                        <div class="info-item">
                            <div class="item-top">
                                <i class="iconfont gradient-blue">&#xeb5d;</i>上次交易时间
                            </div>
                            <div class="item-content">{{infoDetail.lately_transactions_time | formatDate}}</div>
                        </div>
                    </div>
                    <div class="info-line">
                        <div class="info-item">
                            <div class="item-top">
                                <i class="iconfont gradient-green">&#xeb3d;</i>交易总额
                            </div>
                            <div class="item-content">
                                <span class="green">{{infoDetail.transactions_amount}}</span>元
                            </div>
                        </div>
                        <div class="info-item">
                            <div class="item-top">
                                <i class="iconfont gradient-red">&#xe60e;</i>交易笔数
                            </div>
                            <div class="item-content">
                                <span class="red">{{infoDetail.transactions_num}}</span>笔
                            </div>
                        </div>
                    </div>
                    <div class="info-line">
                        <div class="info-item">
                            <div class="item-top">
                                <i class="iconfont gradient-orange">&#xeb3d;</i>平均客单价
                            </div>
                            <div class="item-content">
                                <span class="orange">{{infoDetail.avg_price}}</span>元
                            </div>
                        </div>
                        <div class="info-item">
                            <div class="item-top">
                                <i class="iconfont gradient-blue">&#xe61d;</i>页面停留时间
                            </div>
                            <div class="item-content">
                                <span class="blue">{{infoDetail.stand_time}}</span>秒
                            </div>
                        </div>
                    </div>
                    <div class="info-line">
                        <div class="info-item">
                            <div class="item-top">
                                <i class="iconfont gradient-purple">&#xe66b;</i>订单改价次数
                            </div>
                            <div class="item-content">
                                <span class="red">{{infoDetail.change_num}}</span>次
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail-item" style="width: 1%; flex: 1">
                <div class="detail-title">评价信息</div>
                <div class="evaluate-info">
                    <div class="info-item" v-for="(item, index) in infoDetail.heart" :key="`heart_${index}`">
                        <div class="item-left">评价</div>
                        <div class="item-right">
                            <el-rate :value="item" disabled></el-rate>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="bottom-btn">
            <el-button type="primary" @click="goBack">返回</el-button>
        </div> -->
    </el-scrollbar>
</template>

<script>
    import dayjs from 'dayjs'
    import { stuOpStuOpCustomerInfoDetail, dataCentorTplGetCustomerInfoDetail } from '@/utils/apis.js'
    export default {
        filters: {
            formatDate(val) {
                return dayjs(val).format('YYYY-MM-DD')
            }
        },
        data() {
            return {
                id: Number(this.$route.query.id) || null,
                op_id: Number(this.$route.query.op_id) || null,
                course_id: Number(this.$route.query.course_id) || null,
                infoDetail: {}
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                if (this.$route.query.data_center_tpl_id) { // 电子商务数据分析应用
                    let params = {
                        data_center_tpl_id: this.$route.query.data_center_tpl_id,
                        customer_info_id: this.$route.query.id,
                    }
                    dataCentorTplGetCustomerInfoDetail(params).then((res) => {
                        this.infoDetail = res.data
                    }).catch((err) =>{})
                } else { // 电子商务数据收集清洗
                    let params = {
                        id: this.id
                    }
                    if (this.op_id) {
                        params.op_id = this.op_id
                        params.course_id = this.course_id
                    }
                    stuOpStuOpCustomerInfoDetail(params).then((res) => {
                        this.infoDetail = res.data
                    })
                }

            },
            goBack() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
            & > .el-scrollbar__view {
                min-width: 1400px;
            }
        }
    }
    .top-bar {
        margin: 30px 0 0 44px;
        width: 218px;
        height: 50px;
        background: url("../../../../assets/images/student/customer-info.png") no-repeat;
        .text {
            padding-left: 70px;
            line-height: 50px;
            color: #fff;
            font-size: 20px;
        }
    }
    .detail-box {
        margin: 30px 80px 0 44px;
        display: flex;
        .detail-item + .detail-item {
            margin-left: 40px;
        }
        .detail-item {
            background: #fff;
            border-radius: 10px;
            box-shadow: 0 8px 10px 1px rgba(13, 0, 49, 0.05), 0 8px 25px 4px rgba(13, 0, 49, 0.05);
            .detail-title {
                margin-top: 26px;
                margin-left: 40px;
                position: relative;
                font-size: 18px;
                line-height: 1;
                &:before {
                    content: '';
                    width: 5px;
                    height: 20px;
                    background: #2DC079;
                    border-radius: 3px;
                    position: absolute;
                    left: -15px;
                    top: -1px;
                }
            }
            .customer-info {
                padding: 10px 42px;
                .info-item {
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 18px;
                    margin-top: 40px;
                    .item-left {
                        width: 78px;
                        color: #666;
                        text-align-last: justify;
                    }
                    .item-right {
                        width: 1%;
                        flex: 1;
                        margin-left: 20px;
                        .sex-item {
                            display: inline-block;
                            .iconfont {
                                color: #ccc;
                                font-size: 20px;
                                margin-right: 5px;
                            }
                        }
                        .sex-item + .sex-item {
                            margin-left: 70px;
                        }
                    }
                }
            }
            .buy-info {
                padding: 30px 68px 45px;
                .info-line {
                    margin-top: 15px;
                    border-bottom: 1px solid #eee;
                    display: flex;
                    justify-content: space-between;
                    &:last-child {
                        border-bottom: none;
                    }
                    .info-item {
                        padding: 0 25px 25px;
                        .item-top {
                            display: flex;
                            align-items: center;
                            color: #666;
                            .iconfont {
                                font-size: 20px;
                                margin-right: 8px;
                            }
                        }
                        .item-content {
                            margin-top: 22px;
                            font-size: 18px;
                            text-align: center;
                            span {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
            .evaluate-info {
                padding: 10px 42px;
                .info-item {
                    margin-top: 40px;
                    display: flex;
                    align-items: center;
                    font-size: 18px;
                    .item-left {
                        margin-right: 12px;
                        color: #666;
                    }
                }
            }
        }
    }
    .bottom-btn {
        margin: 120px 0;
        text-align: center;
    }
    .green {
        color: #2DC079;
    }
    .red {
        color: #FF0000;
    }
    .orange {
        color: #FF8A00;
    }
    .blue {
        color: #2461EF;
    }
    .rose-red {
        color: #fe3ec9!important;
    }
    .light-blue {
        color: #74b8ea!important;
    }
    .gradient-blue {
        background-image: linear-gradient(55deg, #7FC5FF 0%, #499EFF 100%);
        -webkit-background-clip: text;
        color: transparent;
    }
    .gradient-green {
        background-image: linear-gradient(55deg, #54E39D 0%, #32C67E 100%);
        -webkit-background-clip: text;
        color: transparent;
    }
    .gradient-red {
        background-image: linear-gradient(55deg, #FF7E69 0%, #F74835 100%);
        -webkit-background-clip: text;
        color: transparent;
    }
    .gradient-orange {
        background-image: linear-gradient(55deg, #FFCC56 0%, #FDA247 100%);
        -webkit-background-clip: text;
        color: transparent;
    }
    .gradient-purple {
        background-image: linear-gradient(55deg, #D1B6FB 0%, #8870FA 100%);
        -webkit-background-clip: text;
        color: transparent;
    }
</style>